<template>
    <div>
        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest
                                        ?'(测试环境,数据定期删除)':''}}当前：明细账</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :md="10">
                                <vxe-toolbar class="pull-left" style=" height: 42px " custom export ref="xToolbar2">
                                </vxe-toolbar>
                                <el-form-item label="会计期间:">
                                    <el-date-picker
                                            v-model="pickerMonthRange"
                                            style=" padding-top: 2px; width: 200px"
                                            type="monthrange"
                                            align="right"
                                            size="mini"
                                            @change="$_pickerMonthRangeEvent(),reportBalanceDetailBook()"
                                            unlink-panels
                                            :clearable="false"
                                            range-separator="至"
                                            start-placeholder="开始月份"
                                            end-placeholder="结束月份">
                                    </el-date-picker>
                                    <el-date-picker
                                            style=" margin-top: 8px; width: 220px"
                                            v-model="pickerDate"
                                            @change="$_pickerDateChangePickerMonthRangeEvent(),reportBalanceDetailBook()"
                                            type="daterange"
                                            size="mini"
                                            :clearable="false"
                                            :picker-options="pickerOptions"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            align="right">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
<!--                            <el-col :sm="4" :md="3">
                                <el-form-item label="科目级别:">
                                    <el-input-number style="width: 80px;" size="mini" v-model="formData.level" clearable
                                                     @change="reportBalanceDetailBook()"
                                                     controls-position="right" :min="1" :max="10"></el-input-number>
                                </el-form-item>
                            </el-col>-->
                            <el-col :md="8">
                                <el-form-item label="科目:">
                                    <el-select
                                            ref="id_subject" v-model="formData.roleSubject"
                                            style="width: 100%"
                                            size="mini"
                                            clearable
                                            collapse-tags
                                            remote
                                            :remote-method="(query) => getRoleSubjectByAccountId(query)"
                                            @change="selectChangeEvent($event),reportBalanceDetailBook()"
                                            filterable
                                            default-first-option
                                            placeholder="请先选择月份，否则没有科目">
                                        <el-option
                                                v-for="item in roleSubjectList"
                                                :key="item.id_subject"
                                                :label="item.id_subject +'-'+item.subject_name"
                                                :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
<!--                            <el-col :sm="12" :md="5">
                                <el-checkbox style="margin-left:2px;margin-top: 12px;" v-model="formData.noHappendMoney"
                                             @change="reportBalanceDetailBook()">无发生额不显示
                                </el-checkbox>
                                <el-checkbox style="margin-top: 12px;" v-model="formData.noMoney"
                                             @change="reportBalanceDetailBook()">无余额不显示
                                </el-checkbox>
                            </el-col>-->
                            <el-col :md="5">
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="reportBalanceDetailBook()" size="mini">搜索
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null || $refs.xGrid.getCurrentRecord().id == -1 "
                                           @click="openVoucher($refs.xGrid.getCurrentRecord())" size="mini">查看凭证
                                </el-button>

                            </el-col>
                        </el-row>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                id="xGridReportBalance"
                                :header-row-style="headerRowStyle"
                                :row-key="true"
                                :export-config="{filename:'总账',type: ['xlsx'] ,types: ['xlsx', 'csv', 'html', 'xml', 'txt'],isFooter:true,isColgroup:true}"
                                align="center"
                                show-footer
                                highlight-current-row
                                :footer-method="footerMethod"
                                :custom-config="{storage:true}"
                                :mouse-config="{selected: true}"
                                :row-class-name="tableRowClassName"
                                :height="(this.getViewHeight() - 130)+''"
                                size="mini"
                                :data="tableDataMain"
                                :columns="tableColumn">
                        </vxe-grid>
                    </el-col>
                </el-row>

            </el-form>
        </el-main>
    </div>

</template>

<script>
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            // 本月第一天
            var startDate = new Date(year + '-' + month + '-01 00:00:00');
            return {
                //自定义表头
                headerRowStyle:{fontSize: '14px'},
                type: '',//空代表默认，CN代表出纳用的（外购单一览）
                tableColumn: [
                    {title: '序号',type: 'seq', width: 40},
                    {
                        field: 'id', width: 180, title: '凭证唯一ID',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/reportBalanceDetailBook/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field: 'type_id', width: 100, title: '凭证字',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/reportBalanceDetailBook/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field: 'voucher_date', width: 180, title: '凭证日期',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/reportBalanceDetailBook/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field: 'subject_id', width: 180, title: '科目代码',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/reportBalanceDetailBook/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field: 'subject_name', width: 320, title: '科目名称',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/reportBalanceDetailBook/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field: 'title', width: 160, title: '摘要',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/reportBalanceDetailBook/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field: 'cash_data_remark', width: 160, title: '备注',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/reportBalanceDetailBook/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field: 'borrow_money', width: 160, title: '借方',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/reportBalanceDetailBook/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field: 'lent_money', width: 160, title: '贷方',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/reportBalanceDetailBook/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field: 'balance_direction', width: 60, title: '#',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/reportBalanceDetailBook/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field: 'balance_money', width: 160, title: '余额',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/reportBalanceDetailBook/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                ],
                tableDataMain: [],
                roleSubjectList:[],//授权科目，可以多选
                filterData: {},
                sortData: {},
                pickerDate: [startDate, this.$getMonthLast(startDate)],
                //pickerDateMonth: startDate,
                pickerMonthRange: [startDate, new Date()],
                formData: {//搜索筛选
                    noHappendMoney: false,
                    noMoney: false,
                    id_subject:'',
                    roleSubject: [],
                    subject_name:'',
                },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                        }
                    },  {
                        text: '本月',
                        onClick(picker) {

                            picker.$emit('pick', [startDate, now]);
                        }
                    },{
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if(month==0){
                                month = 12;
                                year = year-1;
                            }
                            if(month<10){
                                month = '0'+month;
                            }
                            var myDate = new Date(year,month,0);
                            // 上个月第一天
                            var startDate = new Date(year+'-'+month+'-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year+'-'+month+'-'+myDate.getDate()+' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                        }
                    }]
                },
            }
        }, computed: {},
        methods: {
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                    } else {
                        /*console.log('columnIndex',columnIndex)
                        console.log('data',data)
                        console.log('data.length',data.length)
                        console.log('data[data.length]',data[data.length-1])*/
                        if (column.property === 'borrow_money' || column.property === 'lent_money') {
                            sums.push(this.$_sumNum(data, column.property))
                        }else if(data.length > 0 && column.property === 'balance_money'){
                            sums.push(data[data.length-1].balance_money)
                        } else {
                            sums.push('')
                        }
                    }
                })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
            },
            //获取不同用户的授权科目权限
            getRoleSubjectByAccountId(query){
                this.$axios({
                    method:'post',
                    url:'/admin/roleSubject/getRoleSubjectByAccountId',
                    data:{
                        query:query,
                        pickerDate:this.pickerDate,
                        has_child:true
                    }
                }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.roleSubjectList = response.data;
                    }
                }).catch((error) =>{
                    console.log(error)
                });
            },
            openVoucher(row) {
                const {href} = this.$router.resolve({path: '/voucher', query: {id: row.id}})
                window.open(href, '_blank');
            },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
                } else {
                    return '';
                }
            },
            selectChangeEvent(item){
                //console.log('item: ' , item)
                if (item == null || item == ''){
                    this.formData.subject_name = ''
                    this.formData.id_subject = ''
                    this.formData.roleSubject = ''
                }else{
                    this.formData.subject_name = item.subject_name
                    this.formData.id_subject = item.id_subject
                    this.formData.roleSubject = item.id_subject + '-' + item.subject_name
                }
            },
            reportBalanceDetailBook() {
                //this.tableDataMain = []
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let searchUrl = '/admin/reportBalanceDetailBook'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                        formData: this.formData
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        if (response.data.state == 'ok'){
                            this.tableDataMain = response.data.page
                        }else{
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                            });
                            return
                        }
                    }
                }).catch((error) => {
                    loading.close();
                    console.log(error)
                });
            },
        },
        created() {
            this.$nextTick(() => {
                //var startDate = new Date();
                //this.pickerDate = [startDate, this.$getMonthLast(startDate)]

                if (!this.isBlankVue(this.$route.query.id_subject)) {
                    this.formData.id_subject = this.$route.query.id_subject;
                    this.formData.roleSubject = this.$route.query.id_subject;
                }
                if (!this.isBlankVue(this.$route.query.startDate)) {
                    //this.pickerDateMonth = this.$route.query.startDate;
                    this.pickerDate = [];
                    this.pickerMonthRange = [];
                    this.pickerDate.push(this.$route.query.startDate);
                    this.pickerDate.push(this.$getMonthLast(this.$route.query.startDate));
                    this.pickerMonthRange.push(this.$route.query.startDate);
                    this.pickerMonthRange.push(this.$getMonthLast(this.$route.query.startDate));
                    this.reportBalanceDetailBook();
                }
                this.getRoleSubjectByAccountId()
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
            })
        }
    };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
    }

    .el-form-item__content .el-input {
        width: 130px;
    }

    /deep/ .el-input--suffix .el-input__inner {
        padding-right: initial !important;
    }

    /deep/ input[popperclass="myAutocomplete"] {
        padding: 3px !important;
    }

    /deep/ .el-input__suffix {
        right: 0px !important;
    }
</style>
